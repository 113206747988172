import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BookingButton } from "../../components/buttons"

const Index = props => {
  return (
    <Layout {...props}>
      <SEO
        title="個人心理カウンセリング、うつ病・不安障害カウンセリング東京"
        description="個人カウンセリング。一生懸命頑張っているのに上手くいかずに悩んだり、ひたむきに努力しているのになかなか結果が出ずに焦ったり、落ち込んだり．．．私たちの長い人生には、いつも悩みや迷い、不安がつきものです。そんな人生で落ち込みがちなとき、イキイキとした“自分らしさと笑顔”を取り戻していただく場所が、心理カウンセリングの世界。"
      />
      <section className="py-6 md:py-16 bg-image bg-image-plant">
        <div className="container">
          <div className="lg:flex">
            <div className="lg:w-2/3">
              <h1 className="page-title b-line">個人カウンセリング</h1>
              <h2 className="font-light leading-tight">
                一生懸命頑張っているのに上手くいかずに悩んだり、ひたむきに努力しているのになかなか結果が出ずに焦ったり、落ち込んだり．．．
              </h2>
              <p>
                私たちの長い人生には、いつも悩みや迷い、不安がつきものです。そんな人生で落ち込みがちなとき、イキイキとした“自分らしさと笑顔”を取り戻していただく場所が、心理カウンセリングの世界。
              </p>
              <p>
                一人では堂々巡りで、行き詰まりを感じてしまった時に、カウンセラーを相手に、ご自分の問題を話してみる事で、解決へのヒントが見えて来たりするものです。頭では理解している積もりだけど、気持ちが付いていかない‥と云う時にも、カウンセリングを通して気付かずにいた自分や自分を取り巻く環境についての理解を深めることで、人生が楽しく見えてくるかもしれません。まずは「ちょっと息抜き」くらいの楽な気持ちでご連絡ください。きっと、“本当のあなたらしさと笑顔”を呼び起こすヒントが得られるはずですよ。
              </p>
              <h3>たとえばこんな問題があります。</h3>
              <ul className="list-disc list-inside">
                <li>気分障害全般、うつ、双極性障害</li>
                <li>
                  自殺願望、自傷癖（リストカットなど）、セックス依存症、性に興味がない
                </li>
                <li>社会不安障害、極度の緊張症、パニック障害、強迫性障害</li>
                <li>アダルトチルドレンなど、浮気、不倫、三角関係、近親相談</li>
                <li>再婚、家庭内別居、愛憎、性癖、金銭感覚、将来不安</li>
                <li>
                  DV、暴力など、破産、倒産、働けない、リストラ、再就職など
                </li>
                <li>
                  友達、相談相手がいない、孤独感が常にある、一人暮らしが長く親友がいない
                </li>
                <li>雇用不安、セクハラ、パワハラ</li>
                <li>上司、部下、先輩、後輩、取引先、顔見知りとの付き合い方</li>
                <li>
                  コミュニケーションが下手、人付き合いが苦手、作業や段取りを覚えれずにしかれる
                </li>
                <li>環境になれない、適用障害、会わない人が多い</li>
                <li>
                  子供のしつけの加減度合、子供が虐めれている、虐めている、引きこもり、自閉症ぎみ
                </li>
                <li>
                  シングルマザー、ファーザー、人との付き合いの仕方が分からない、友達ができない
                </li>
                <li>
                  夫婦がセックスレス、大事なパートナーなのにセックスできない
                </li>
                <li>
                  LGBTQの方々のお悩み全般、女性同性愛者、男性同性愛者、両性愛車、性同一性障害、周囲に認知してほしい、誰にも知られたくない、好きな人がいる
                </li>
                <li>
                  プラスに、ポジティブに考えたい、成長したい、成功したい、鈍感力や判断力を高めたい
                </li>
                <li>
                  人の気持ちをわかるようになりたい、聞き上手、話し上手になりたい
                </li>
                <li>
                  人に好かれるようになりたい、嫌なことを受け流せるようにないりたい
                </li>
                <li>
                  性格の改善、習慣の改善、世間一般の評価のセンスを知りたい
                </li>
                <li>
                  異性に好かれる、惚られるようになりたい、もっと仕事ができるようになりたい
                </li>
                <li>余裕や器を広げる考え方を習いたい</li>
              </ul>
              <p>
                特に病気で悩んでないけども、なんとなく寂しい、ただ相談相手が欲しい、普段ずっと一人だからといった方も歓迎します。
              </p>
              <p>
                言い方や病名に関係なく、違和感や気持ち悪さ、怖さや不自然さを強く感じて落ち着かないような状態が続く方も、内容に関係なくカウンセリングを利用されることをお勧めします。
              </p>
              <div className="text-center md:text-left">
                <BookingButton />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Index
